import * as React from "react"
import YetiFooter from "./../images/svg/yeti-footer.svg"
import { Link, navigate } from "gatsby"
import Fade from "react-reveal"

const Footer = () => {

return (
  
    <div className="relative pt-20 lg:pt-24">
      <Fade duration={500}>
        <div className="yeti-footer group m-auto flex md:space-x-2 justify-center">
          <button onClick={() => { navigate(-1) }} className="footer-link">Back</button>
          <div className="w-1/2 md:w-2/5 lg:w-4/12 max-w-md pl-4 md:pl-6">
          <YetiFooter />
          </div>
          <Link to="/" className="footer-link">Home</Link>
        </div>
      </Fade>
      <div className="gradient-bar opacity-0 duration-200 bg-gradient-to-r from-yellow via-orange to-pink h-4 absolute bottom-0 left-0 w-full"></div>
    </div>

  )
}

export default Footer;